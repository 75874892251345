import React from 'react';
import { Link } from 'gatsby';
import { LazyLoadImage } from 'react-lazy-load-image-component';
import 'react-lazy-load-image-component/src/effects/blur.css';
import logo from '../../images/m-school-logo.svg';

import Layout from '../../components/mschool/layout';
import SEO from '../../components/seo';
import useWindowSize from '../../hooks/useWindowSize';
import settings from "../../../settings";
import VideoBackground from '../../components/videoBackground';
import BmwMSchoolGlance from '../../components/bmwGlance/bmwMSchoolGlance';

const videoSrc = `${settings.IMAGES_BASE_URL}/v2/videos/Sibling_Rivlary_FINAL_V2_16x9_COMPRESSED.mp4`;

const MSchoolPage = () => {
    const {width} = useWindowSize();
    const isMobile = width < 1200;
    const isDesktop = width >= 1200;
    return (
      <Layout>
        <SEO title="BMW M School | BMW Performance Driving School" 
          description="BMW M School puts you behind the wheel of a BMW M car on a closed course with pro driving instructors. Every BMW M driving experience gives you the thrills of driving a BMW M2, M3, M4 or M5 at the limit."
          keywords="bmw m4 gt4 driving experience, bmw m performance driving school, bmw m performance center, extreme driving school, bmw m performance school, advanced driving school" 
        />
          {isDesktop && (
            <div className="mschoolpage">
              <VideoBackground
                url={videoSrc}
                topLeftChildren={
                  <div className="header">
                    <Link to="/" aria-label="Home">
                      <LazyLoadImage
                        effect="blur"
                        src={logo}
                        alt="BMW-logo"
                        className="bmw-logo mobile-logo-top"
                      />
                    </Link>
                    <h1>
                      Know your Limits, <br /> then drive past them.
                    </h1>
                  </div>
                }
              />
              <section className="mschool__tabs">    
                <ul>
                  <li>
                    <Link to="/mschool/mdriver">
                      <button className="btn btn-black btn-mschool">
                        <span>M Driver's Package</span>
                      </button>
                    </Link>
                  </li>
                  <li>
                    <Link to="/mschool/onedaymschool">
                      <button className="btn btn-black btn-mschool">One-Day M School</button>
                    </Link>
                  </li>
                  <li>
                    <Link to="/mschool/twodaymschool">
                      <button className="btn btn-black btn-mschool">Two-Day M School</button>
                    </Link>
                  </li>
                  <li>
                    <Link to="/mschool/advancedmschool">
                      <button className="btn btn-black btn-mschool">Advanced M School</button>
                    </Link>
                  </li>
                  <li>
                  <Link to="/mschool/m4gt4">
                            <button className="btn btn-black btn-mschool">M4 GT4 School</button>
                          </Link>
                  </li>
                  <li>
                    <Link to="/mschool/racelicenseschool">
                      <button className="btn btn-black btn-mschool">Race License School</button>
                    </Link>
                  </li>
                </ul>
              </section>
              <section className="mschool-cta">
                <div className="container">
                  <h4>BMW M SCHOOL</h4>
                  <h2>THE BMW M DRIVING EXPERIENCE</h2>
                  <div className='row'>
                    <div className='col'>
                      <div className='title'>M Driver's Program</div>
                      <p>
                        This is M 101 - designed to help you learn the limits of BMW M cars while exploring your own.
                      </p>
                      <Link to="/mschool/mdriver">
                        <button className="explore-btn">Explore</button>
                      </Link>
                    </div>
                    <div className='col'>
                      <div className='title'>Two-Day M School</div>
                      <p>M School is all about limits, and you’ll discover just how high we set them.</p>
                      <Link to="/mschool/twodaymschool">
                        <button className="explore-btn">Explore</button>
                      </Link>
                    </div>
                    <div className='col'>
                      <div className='title'>M4 GT4 School</div>
                      <p>This M4 grips harder, accelerates faster and brakes shorter than anything before.</p>
                      <Link to="/mschool/m4gt4">
                        <button className="explore-btn">Explore</button>
                      </Link>
                    </div>
                  </div>
                  <div className='row'>
                    <div className='col'>
                      <div className='title'>One-Day M School</div>
                      <p>
                        The modern BMW M car is so capable, you’ll be astonished by what it can do on track.
                      </p>
                      <Link to="/mschool/onedaymschool">
                        <button className="explore-btn">Explore</button>
                      </Link>
                    </div>
                    <div className='col'>
                      <div className='title'>Advanced M School</div>
                      <p>Two days of action that allow you to truly unleash the M beast.</p>
                      <Link to="/mschool/advancedmschool">
                        <button className="explore-btn">Explore</button>
                      </Link>
                    </div>
                    <div className='col'>
                      <div className='title'>Race License School</div>
                      <p>This BMW M training gets you SCCA certified. Take your first steps to becoming a pro driver.</p>
                      <Link to="/mschool/racelicenseschool">
                        <button className="explore-btn">Explore</button>
                      </Link>
                    </div>
                  </div>
                </div>
              </section>
            <BmwMSchoolGlance/>
            </div>
          )}
          <div className="mschoolpage">
            {isMobile && (
              <div className="mschoolpage__mobile">
                <div className="background">
                </div>
                <div className="container">
                  <h2>THE BMW M DRIVING EXPERIENCE</h2>
                  <h4>BMW M SCHOOL</h4>
                  <div className='row'>
                    <div className='col'>
                      <div className='title'>M Driver's Program</div>
                      <p>
                        This is M 101 - designed to help you learn the limits of BMW M cars while exploring your own.
                      </p>
                      <Link to="/mschool/mdriver">
                        <button className="explore-btn">Explore</button>
                      </Link>
                    </div>
                    <div className='col'>
                      <div className='title'>One-Day M School</div>
                      <p>
                        The modern BMW M car is so capable, you’ll be astonished by what it can do on track.
                      </p>
                      <Link to="/mschool/onedaymschool">
                        <button className="explore-btn">Explore</button>
                      </Link>
                    </div>
                    <div className='col'>
                      <div className='title'>Two-Day M School</div>
                      <p>M School is all about limits, and you’ll discover just how high we set them.</p>
                      <Link to="/mschool/twodaymschool">
                        <button className="explore-btn">Explore</button>
                      </Link>
                    </div>
                    <div className='col'>
                      <div className='title'>Advanced M School</div>
                      <p>Explore the limits on high-speed tracks that get you closer to becoming a true pro.</p>
                      <Link to="/mschool/advancedmschool">
                        <button className="explore-btn">Explore</button>
                      </Link>
                    </div>
                    <div className='col'>
                      <div className='title'>M4 GT4 School</div>
                      <p>This M4 grips harder, accelerates faster and brakes shorter than anything before.</p>
                      <Link to="/mschool/m4gt4">
                        <button className="explore-btn">Explore</button>
                      </Link>
                    </div>
                    <div className='col'>
                      <div className='title'>Race License School</div>
                      <p>This BMW M training gets you SCCA certified. Take your first steps to becoming a pro driver.</p>
                      <Link to="/mschool/racelicenseschool">
                        <button className="explore-btn">Explore</button>
                      </Link>
                    </div>
                  </div>
                </div>
              <BmwMSchoolGlance/>
              </div>
            )}
        </div>
      </Layout>
    );
}

export default MSchoolPage;
